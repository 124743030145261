<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 贡献值审核
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container" v-loading="loading">
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Title" label="社区项目" width="190"></el-table-column>
        <el-table-column prop="TitleName" label="活动昵称" width="250" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="分配贡献值详情">
          <template #default="scope">
            <el-button type="text" @click="openDetails(scope.row.initData)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="ToNumber" label="总贡献值">
          <template #default="scope">
            {{ scope.row.ToNumber }}QB
          </template>
        </el-table-column>
        <el-table-column prop="NewTime" label="创建日期"></el-table-column>


        <el-table-column width="180" label="操作">
          <template #default="scope">
            <el-button
                class="green"
                type="text"
                @click="launch( scope.row.id,scope.row.App_id)"
            >通过
            </el-button>
            <el-button
                type="text"
                class="red"
                @click="fail( scope.row.id,scope.row.App_id)"
            >不通过
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="query.pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <DetailsType ref="DetailsType"></DetailsType>
  </div>
</template>

<script>
import {
  fetchData, setfail,
  setlaunch
} from "../../api/Integral/Audit";
import {openFileId} from "../../api/Contract";
import DetailsType from './com/DetailsType'

export default {
  data() {
    return {
      loading: false,
      query: {
        name: "",
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tableData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    openDetails(TypeData) {
      let data = JSON.parse(TypeData)
      this.$refs.DetailsType.open(data)
    },
    // //查询数据
    getData() {
      this.loading = true
      fetchData({
        skip: this.query.pageIndex
      }).then(res => {
        this.loading = false
        let bindata = res.sum;
        let number = res.number
        this.query.pageTotal = number
        this.tableData = bindata;
      });
    },

    openfile(FileId) {
      openFileId({FileId}).then(res => {
        if (res.code == 0) {
          window.open(res.downloadUrl)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    handlePageChange(index) {
      this.query.pageIndex = index
      this.getData()
    },
    launch(id, App_id) {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      this.$confirm("确定要执行通过吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading()
        setlaunch({Admin_id, id, App_id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        });
      }).catch(() => {
      });

    },
    fail(id, App_id) {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id

      this.$confirm("确定要执行不通过吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading()
        setfail({Admin_id, id, App_id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        });

      }).catch(() => {
      });
    },
    openLoading() {
      this.loadings = this.$loading({
        lock: true,//lock的修改符--默认是false
        text: '处理中',//显示在加载图标下方的加载文案
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
      });
    },

    closeLoading() {
      this.loadings.close();
    },
  },
  components: {
    DetailsType
  }
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.green {
  color: green;
}

.Name {
  margin-right: 10px;
}

</style>
