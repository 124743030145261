import request from '../../utils/request';

/**
 * 项目发起审核
 * @param query
 * @returns {AxiosPromise}
 */

//查询数据
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Audit/find',
        method: 'get',
        params: query
    });
};


//通过申请
export const setlaunch = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Audit/set',
        method: 'post',
        data: query
    });
};


//不通过申请
export const setfail = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Audit/fail',
        method: 'post',
        data: query
    });
};


